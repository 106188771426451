import React from "react";
import PageContainer from "../../../components/container/Container";
import ServicesHeader from "../../../components/services/ServicesHeader";
import WhatWeOffer from "../../../components/services/WhatWeOffer";
import { kubernetWhatWeOffer } from "../../../data/servicesOffered";
import HowItWorks from "../../../components/services/HowItWorks";
import { kubernetHowItWorkData } from "../../../data/howServiceWorks";
import ExclusiveFeatures from "../../../components/services/ExclusiveFeatures";
import { kubernetFeaturesData } from "../../../data/exclusiveFeatures";
import bg1 from "../../../assets/images/Banner_imgs/services_3.jpg";

function kubernetes_adoption() {
  return (
    <>
      <PageContainer title="Data AI/ML" description="">
        <ServicesHeader title={"Data AI/ML"} bgImage={bg1} />
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="text-muted mb-0">
                  {
                    "In today’s data-driven world, businesses must harness the power of Data, Artificial Intelligence (AI), and Machine Learning (ML) to drive innovation, efficiency, and decision-making. Our expertise in AI and ML enables organizations to extract meaningful insights, automate processes, and build intelligent systems tailored to their unique needs."
                  }
                </p>
                <WhatWeOffer data={kubernetWhatWeOffer} />
                <HowItWorks data={kubernetHowItWorkData} />
                <ExclusiveFeatures data={kubernetFeaturesData} />
              </div>
            </div>
          </div>
        </section>
      </PageContainer>
    </>
  );
}

export default kubernetes_adoption;
