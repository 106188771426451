import React from "react";
import { Link, useNavigate } from "react-router-dom";
import bg1 from "../../assets/images/Banner_imgs/bannerImg.jpg";
import OSIcon from "../../assets/images/omniscientiaLogoWithTitle.png";
import PUIcon from "../../assets/images/PUlogo.png";
import deepakImage from "../../assets/images/team/Deepak.png";
import rajeshImage from "../../assets/images/team/RajeshImage.jpg";
import shaileshImage from "../../assets/images/team/Shailesh.png";
import cdImage from "../../assets/images/team/chandrakant-dollin.jpg";
import PageContainer from "../../components/container/Container";
import Testimonials from "../../components/testimonials/Testimonials";

export default function IndexCorporate() {
  const navigate = useNavigate();

  const servicesData = [
    {
      icon: OSIcon,
      title: "",
      link: "https://omniscientia.codeelan.com/",
      desc: "Powered by cutting-edge neural networks and sophisticated image processing techniques, our platform enables businesses to automate visual tasks with unmatched accuracy, efficiency, and scalability.",
    },
    {
      icon: PUIcon,
      title: "",
      link: "https://perfectus.codeelan.com/",
      desc: "Revolutionize Testing Across Web, API, Mobile & Performance.",
    },
    // {
    //   icon: cloudEnggIcon,
    //   title: "Customized Cloud Services",
    //   link: "/services/cloud",
    //   desc: "Embedding observability into your cloud infrastructure and applications provides crucial insights into performance, availability, and user experience.",
    // },
    // {
    //   icon: observEnggIcon,
    //   title: "Customized Data AI/ML Services",
    //   link: "/services/data-aiml",
    //   desc: "Before embarking on your cloud journey, it’s essential to lay a solid foundation that aligns with your business objectives.",
    // },
  ];
  const ctaData = [
    {
      image: deepakImage,
      desc: " Deepak, Founder and COO, with 24 years of experience, specializes in automation, software quality, project management, and AI/Computer Vision. He holds a Computer Science degree from Bharati Vidyapeeth, Pune, along with certifications in testing, AWS, Agile, and project management.",
      name: "Deepak Mathur",
      title: "Chief Operating Officer",
    },
    {
      image: cdImage,
      name: "Chandrakant Dollin",
      desc: " CD, Partner and CEO of CodeElan Technologies, has 25+ years of experience in enterprise technology, AI, and Computer Vision, leading digital transformations. Based in Silicon Valley, he's a UC Berkeley engineering alumnus with a strong track record in growing software products.",
      title: "Chief Executive Officer",
    },
    {
      image: shaileshImage,
      desc: "Shailesh Amdekar, CSP, with 27-year career has been a masterclass in leadership, business acumen, and customer relationships. With an eye for operational excellence and a knack for risk management, Shailesh has helped numerous organizations thrive. His approach is analytical and profoundly human—he sees numbers and the people and stories behind them.",
      name: "Shailesh amdekar",
      title: "Consultant Strategic Business Partner",
    },
    {
      image: rajeshImage,
      desc: " Rajesh Kanade, 25+ years of industry experience in software development with 16+ years in leadership roles, primarily in Unified Communication, Contact centers & Cybersecurity and 1 US Patent granted.Thought Leader with a passion for building high-performing teams responsible for conceptualizing brand-new products and delivering them to market.",
      name: "Rajesh Kanade",
      title: "Chief Technical Officer",
    },
  ];
  return (
    <>
      <PageContainer
        title={"CodeElan Technologies - Where AI Makes Real-World Impact"}
        description={"This page is the home page for CodeElan technologies."}
      >
        <section
          className="bg-half-170 bg-light d-table w-100 "
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="bg-overlay bg-gradient-overlay"></div>
          <div className="container">
            <div className="row mt-5 justify-content-center">
              <div className="col-12">
                <div className="title-heading text-center">
                  {/* <h4 className="display-3 mb-2 fw-bold title-dark">
                    <span className="you-heading">
                      Where AI Makes Real-World Impact
                    </span>{" "}
                    <span className="text-green">IT</span> <br />{" "}
                    <span className="we-heading">We Deliver</span>{" "}
                    <span className="text-green">IT</span>{" "}
                  </h4> */}
                  <h4 className="heading fw-bold page-heading text-white title-dark">
                    Where AI Makes Real-World Impact
                  </h4>
                  {/* <p className="para-desc text-muted bannerText">
                    CodeElan Technologies specializes in AI/ML, custom software
                    development, cloud engineering, product engineering, and
                    quality assurance across various sectors, including Fintech,
                    Healthcare, Manufacturing, and Education.
                  </p> */}
                  {/* <div className="mt-4 mb-2 pt-2">
                    <Link
                      to="contactus"
                      className="btn btn-lg btn-pills btn-primary"
                    >
                      Contact Us
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionDummy mt-0 pt-4">
          <div className="container mt-0 pb-5">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-title text-center pt-0">
                  <p className="text-muted mx-auto mb-0">
                    At CodeElan, we specialize in AI/ML, Computer Vision, and
                    cloud engineering solutions that drive innovation. We
                    deliver scalable, intelligent products, automate processes,
                    and provide actionable insights. With AGILE development and
                    robust QA automation, we help enterprises integrate and
                    scale cutting-edge technologies to enhance their digital
                    transformation.
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "12px",
              }}
              className="hover-card-container"
            >
              {servicesData.map((item, index) => {
                return (
                  <div
                    className="mt-4 pt-2 cursor-pointer hover-card-subcontainer"
                    key={index}
                    onClick={() => {
                      if (item.link.startsWith("http")) {
                        window.open(item.link, "_blank", "noopener,noreferrer");
                      } else {
                        navigate(item.link);
                      }
                    }}
                  >
                    <div
                      className="features p-2 rounded shadow"
                      style={{
                        minHeight: "240px",
                      }}
                    >
                      <div className="rounded text-white title-dark">
                        <img
                          src={item.icon}
                          height="50px"
                          // width="120px"
                          className={index === 0 ? "mx-3" : "none"}
                          style={{
                            transform: index === 1 ? "scale(0.7)" : "none",
                          }}
                        />
                        <Link to={item.link} className="title h5 text-dark">
                          {item.title}
                        </Link>
                      </div>

                      <div className="content mt-1 p-2">
                        <p className="text-muted para mt-2 mb-0">{item.desc}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bg-light mt-0 pt-2 pb-5 ">
            <div className="container mt-10 pt-4">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title fw-semibold mb-3">Testimonials</h4>
                    <h6>We value the feedback from our clients.</h6>

                    <p className="text-muted mx-auto mb-0">
                      Our success stories speak for themselves, showcasing why
                      clients trust us with their software development needs,
                      quality engineering projects, and digital transformation
                      initiatives.
                    </p>
                  </div>
                  <div className="col-12">
                    <Testimonials />
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-100">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="section-title text-center ">
                    <h4 className="title fw-semibold mb-3">About Us</h4>
                    <h5>We are CodeElan Technologies</h5>
                    <p className="text-muted mx-auto mb-0">
                      We specialize in cutting-edge Computer Vision AI and QA
                      software solutions, leveraging deep learning, edge AI, and
                      advanced automation to optimize complex workflows. Our
                      platforms integrate real-time image processing, object
                      detection, and anomaly detection with AI-driven testing
                      frameworks for robust quality assurance. We engineer
                      scalable, high-performance systems that enhance defect
                      detection, automate visual inspections, and streamline
                      software validation. With expertise in model optimization,
                      IoT integration, and intelligent test automation, we
                      deliver precise, reliable, and efficient AI-powered
                      solutions for diverse industries.
                    </p>
                    {/* <h4 className="mt-5 mb-3">Our Expertise</h4> */}
                    {/* <div className="row justify-content-center">
                      <div className="col-12 col-md-4">
                        <div className="features feature-green feature-bg p-4 rounded shadow">
                          <div className="content mt-3">
                            <div className=" h5 text-dark para">
                              Software Product Engineering
                            </div>
                            <p className="text-muted para mt-2 mb-0 ">
                              Drive product development with end-to-end
                              engineering support, from initial concept design
                              to rigorous quality assurance and deployment.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="features feature-green feature-bg p-4 rounded shadow">
                          <div className="content mt-3">
                            <div className=" h5 title-dark para">
                              Global Talent Solutions
                            </div>
                            <p className="text-muted para mt-2 mb-0 ">
                              Leverage top-tier engineering talent with
                              flexible, cost-effective outsourcing
                              options—streamlining team integration and reducing
                              time-to-market.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-half-95 pb-5 d-table w-100 pt-2"
          style={{
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            marginTop: "24px",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title fw-semibold mb-3">Leadership Team</h4>
                <p className="text-muted mx-auto mb-0 ">
                  Our leadership team brings together decades of experience
                  crafting sophisticated custom software solutions, pioneering
                  automation frameworks, and delivering superior-quality
                  engineering for enterprises worldwide. They are the architects
                  behind CodeElan’s commitment to excellence, ensuring every
                  solution is designed for real-world scalability and
                  innovation.
                </p>
              </div>
              <div class="row justify-content-md-between justify-content-sm-center">
                {ctaData.map((item) => (
                  <div class="col-12 col-md-6 col-lg-3 flip-container rounded-4 cursor-pointer mt-sm-3 mt-lg-0">
                    <div class="flip-card shadow-xs features feature-green rounded-4">
                      <div class="text-center bg-light pt-5 card-front rounded-4">
                        <img
                          src={item.image}
                          className="avatar avatar-large rounded-pill mx-auto"
                          alt=""
                        />
                        <div className="features feature-green">
                          <div className="name my-5 ">
                            <small className="text-uppercase fw-semibold d-block">
                              {item.name}
                            </small>
                            <small className="text-muted">{item.title}</small>
                          </div>
                        </div>
                      </div>
                      <div class="text-start bg-green text-white p-4 card-back rounded-4 justify-text">
                        <small>{item.desc}</small>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </PageContainer>
    </>
  );
}
