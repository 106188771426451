import React, { useState } from "react";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/Banner_imgs/contactus.jpg";

import {
  FiPhone,
  FiMail,
  FiMapPin,
  IoIosArrowDown,
  IoIosArrowUp,
} from "../assets/icons/vander";
import PageContainer from "../components/container/Container";
import { apiCall } from "../utils/ApiUtil";
import { toast } from "react-toastify";

const servicesOptions = [
  { lable: "OmniScientia", value: "computer_vision" },
  { lable: "Perfectus", value: "test_automation" },
  // { lable: "Customized Cloud Services", value: "cloud_engineering" },
  // { lable: "Customized Data AI/ML Services", value: "ai_ml" },
  // { lable: "Product Development", value: "product_development" },
  // { lable: "Observability Engineering", value: "observability_engineering" },
];

export default function Contact() {
  const [selectOpen, setSelectOpen] = useState(false);
  const [error, setErrors] = useState({
    name: "",
    email: "",
    services: "",
    subject: "",
    comments: "",
  });

  const validate = (data) => {
    const { name, email, service, subject, comments } = data;
    let isError = false;
    const errorsCopy = { ...error };

    if (name?.trim() === "") {
      isError = true;
      errorsCopy.name = "Please enter your Name.";
    } else {
      errorsCopy.name = "";
    }
    if (email.trim() === "") {
      isError = true;
      errorsCopy.email = "Please enter your Email.";
    } else {
      errorsCopy.email = "";
    }
    if (service.trim() === "") {
      isError = true;
      errorsCopy.services = "Please select Service you are intested in.";
    } else {
      errorsCopy.services = "";
    }
    if (subject.trim() === "") {
      isError = true;
      errorsCopy.subject = "Please enter Subject.";
    } else {
      errorsCopy.subject = "";
    }
    if (comments.trim() === "") {
      isError = true;
      errorsCopy.comments = "Please enter Comment.";
    } else {
      errorsCopy.comments = "";
    }

    setErrors({ ...errorsCopy });
    return isError;
  };

  const handleformSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    const name = form.elements["name"].value;
    const email = form.elements["email"].value;
    const service = form.elements["service"].value;
    const subject = form.elements["subject"].value;
    const comments = form.elements["comments"].value;

    const payload = {
      email,
      action: "storeContactUs",
      data: {
        name,
        service,
        subject,
        comments,
      },
    };

    if (!validate({ name, email, service, subject, comments })) {
      const response = await apiCall(payload);
      if (response?.status === 200 && response?.data?.statusCode === 200) {
        toast.success("Contact Request sent Successfully.");
        form.reset();
      }
    }
  };

  return (
    <PageContainer
      title={"Contact Us"}
      description={"This is a contact page for CodeElan Technologies."}
    >
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: "top" }}
      >
        <div className="bg-overlay bg-gradient-overlay"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <small className="text-white-50 mb-1 fw-medium text-uppercase mx-auto">
                  Get in touch
                </small>
                <h5 className="heading fw-semibold mb-0 page-heading text-white title-dark">
                  Contact
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section pb-0">
        <div className="container  mb-8">
          <div className="row justify-content-lg-between align-items-end">
            <div className="col-lg-7">
              <div className="section-title mb-5 pb-2 text-center">
                <h4 className="title mb-1">Get In Touch !</h4>
                {/* <p className="text-muted para-desc mx-auto mb-0">
                  We specialize in AI/ML and Computer Vision products that drive
                  innovation, QA automation for seamless software performance,
                  and cloud engineering services for scalable, reliable
                  solutions. Learn more about how we can elevate your business!
                </p> */}
              </div>
              <div className="custom-form  ">
                <form onSubmit={handleformSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Name :"
                        />
                        {error?.name ? (
                          <span className="text-danger">{error.name}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Email <span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Email :"
                        />
                        {error?.email ? (
                          <span className="text-danger">{error.email}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3 select-input">
                        <label className="form-label">
                          Services/Technologies interested in{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div
                          onClick={() => setSelectOpen(!selectOpen)}
                          onBlur={() => setSelectOpen(false)}
                        >
                          <select
                            name="service"
                            id="service"
                            className="form-control cursor-pointer"
                            style={{ background: "transparent" }}
                          >
                            <option value={""}>Select Service:</option>
                            {servicesOptions.map((service) => (
                              <option
                                key={service.lable + service.value}
                                value={service.value}
                              >
                                {service.lable}
                              </option>
                            ))}
                          </select>
                          <span className="arrow">
                            {!selectOpen ? (
                              <IoIosArrowDown />
                            ) : (
                              <IoIosArrowUp />
                            )}
                          </span>
                          {error?.services ? (
                            <span className="text-danger">
                              {error.services}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Subject <span className="text-danger">*</span>
                        </label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-control"
                          placeholder="Subject :"
                        />
                        {error?.subject ? (
                          <span className="text-danger">{error.subject}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Comments <span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control"
                          placeholder="Message :"
                        ></textarea>
                        {error?.comments ? (
                          <span className="text-danger">{error.comments}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              style={{ height: "505px" }}
              className="col-sm-4 col-sm-push-4 justify-content-center shadow-lg bg-light py-3"
            >
              <div className="row-md-10 mt-4 mt-sm-0 pt-2 pt-sm-2">
                <div className="card border-0 text-center features feature-clean bg-transparent">
                  <div className="icons text-green text-center mx-auto">
                    <FiPhone className="d-block rounded h3 mb-0" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="footer-head">Phone</h5>
                    <Link to="tel:(820) 865-0598" className="text-foot">
                      (820) 865-0598
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row-md-10 mt-4 mt-sm-0 pt-2 pt-sm-2">
                <div className="card border-0 text-center features feature-clean bg-transparent">
                  <div className="icons text-green text-center mx-auto">
                    <FiMail className="d-block rounded h3 mb-0" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="footer-head">Email</h5>
                    <Link to="mailto:info@codeelan.com" className="text-foot">
                      info@codeelan.com
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row-md-10 mt-4 mt-sm-0 pt-2 pt-sm-2">
                <div className="card border-0 text-center features feature-clean bg-transparent">
                  <div className="icons text-green text-center mx-auto">
                    <FiMapPin className="d-block rounded h3 mb-0" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="footer-head">Location</h5>
                    <h6>India</h6>
                    <hr />
                    <h6>USA</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section pb-0">
        <div className="container-fluid mt-100 mt-60">
          <div className="row">
            <div className="col-12 p-0">
              <div className="card map border-0">
                <div className="card-body p-0">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.6071157416127!2d73.78960327441675!3d18.591743266997966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b976ff115aaf%3A0x765e650834bfa995!2sCodeElan%20Technologies!5e0!3m2!1sen!2sin!4v1725558372313!5m2!1sen!2sin"
                    style={{ border: "0" }}
                    title="CodeElan"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageContainer>
  );
}
